pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

#view-default-view {
  z-index: 1;
}

.mapboxgl-ctrl-bottom-right {
  pointer-events: all !important;
}

#okta-signin-submit {
  border-color: #da5729 !important;
}

.email-button {
  border-color: #da5729 !important;
}

.okta-widget-container {
  display: flex;
  justify-content: center;
}

.auth-container {
  width: 30rem !important;
}

p {
  margin-bottom: 0;
}

.MuiDataGrid-columnsContainer {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
